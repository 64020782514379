/* eslint-disable react/jsx-key */
import React from "react"

//logo de empresas clientes
import Aviva from "@components/sharedComponents/customersLogos/logos/Aviva.svg"
import Synlab from "@components/sharedComponents/customersLogos/logos/Synlab.svg"
import Auna from "@components/sharedComponents/customersLogos/logos/Auna.svg"
import PulsoSalud from "@components/sharedComponents/customersLogos/logos/PulsoSalud.svg"
import ClinicaPiel from "@components/sharedComponents/customersLogos/logos/ClinicaPiel.svg"
import Idisac from "@components/sharedComponents/customersLogos/logos/Idisac.svg"

//image acordeon left
import ImageAccordionLeftOne from "@components/pageAbm/pageRicardo/image/imageOne.png" 
import ImageAccordionLeftTwo from "@components/pageAbm/pageRicardo/image/imageTwo.png" 
import ImageAccordionLeftThree from "@components/pageAbm/pageRicardo/image/imageThree.png"

import imageVideo from "@components/sharedComponents/bannerVideo/image/imageSalud.jpg"
import imgStart from "@components/pageAbm/pageRicardo/image/imageLogo.svg"
import imgBanner from "@components/pageAbm/pageRicardo/image/banner.png"

const titleStart = (<p className="guide__title">
    Hola, Clínica Ricardo Palma, ¿quieres <span style={{ color: '#00B2A9' }}>superar tu índice NPS de 62.15? </span> Descubre cómo lograrlo
</p>
)

const descripcionStart = (<p>
    Entérate cómo Beex puede ayudarte a automatizar tu gestión de citas, enviar recordatorios automáticos, integrar tus sistemas internos y elevar la satisfacción de tus pacientes.
    <br /><br />
    Con nuestras soluciones de automatización podrías optimizar recursos, reducir costos y transformar tu atención en un servicio más ágil, eficiente y centrado en las personas.
</p>)

const data = {
    start: {
        title: titleStart,
        textbody: descripcionStart,
        img: imgStart,
        banner: null,
        inputText: "Puesto en Clínica Ricardo Palma",
    },
    customersDesktop: [
        Aviva,
        Synlab,
        Auna,
        PulsoSalud,
        ClinicaPiel,
        Idisac
    ],
    customersTablet: {
        arrayOne: [Aviva, Synlab, Auna, PulsoSalud, ClinicaPiel, Idisac],
    },
    contacts: {
        title: "Sé la empresa que genere la mejor conexión con sus pacientes",
        textbody: "Estás a un paso de revolucionar las interacciones con tus pacientes. Agenda una reunión para que uno de nuestros especialistas se involucre con tus objetivos.",
        href: "/hablemos/",
        btn: "Quiero una asesoría gratuita",
    },
    accordionRight: [
        {
            title: "Gestiona las citas vía telefónica y por WhatsApp ",
            description: "<p>De acuerdo con un análisis en Ahrefs, más de 1.000 usuarios buscan “Clínica Ricardo Palma citas” cada mes. Esto demuestra la importancia de ofrecer canales accesibles y eficientes para gestionar citas, especialmente considerando las diferentes generaciones que utilizan estos servicios. <br /><br /> En lugar de depender de formularios largos con más de 10 campos, simplifica la experiencia con un IVR automatizado o un chatbots por WhatsApp. Todo esto se gestiona desde una sola plataforma, reduciendo la carga operativa de tus agentes y mejorando significativamente la experiencia del paciente.</p>",
            image: ImageAccordionLeftOne
        },
        {
            title: "Agiliza y enruta la atención telefónica",
            description: "<p>Tener múltiples números puede generar confusión en los pacientes. Centraliza toda tu atención en un solo número con Beex y facilita la gestión para tus usuarios y agentes. Además, un número memorable como 224-2224 incrementa la recordación y confianza en el servicio.<br /><br /> Con Beex, puedes enrutar automáticamente las llamadas según las necesidades del paciente: sede, especialidad, reclamos, o incluso redirigir la atención a WhatsApp para consultas rápidas y efectivas.</p>",
            image: ImageAccordionLeftTwo
        },
        {
            title: "Utiliza un IVR inteligente",
            description: "<p>Según SEMrush, alrededor de 2,4k usuarios buscan mensualmente el número de la clínica Ricardo Palma. Esto refleja una alta demanda que podría saturar tus líneas. Si las llamadas se alargan con opciones de digitar el anexo o esperar que una gente los atienda, aumentará la frustración de los pacientes. <br/> <br/> Con un IVR inteligente de Beex, puedes ofrecer opciones claras de marcado para que los pacientes lleguen al área o departamento correcto sin complicaciones. Estudios de Forrester muestran que el 73% de los clientes valoran un servicio eficiente que respete su tiempo. ¡Hazlo posible!</p>",
            image: ImageAccordionLeftThree,
        },
    ],
    footer: {
        product: "PRODUCTOS",
        productDesktopOne: "Conversations: Atención al cliente omnicanal y chatbots.",
        productMobileOne: "Conversations",
        linkone: "/conversations/",
        productDesktopTwo: " Contact Center: Software en la nube para Contact Center.",
        productMobileTwo: "Contact Center",
        linktwo: "/contact-center/",
        productDesktopFour: "Validation: Software de validación de teléfono.",
        productMobileFour: "Validation",
        linkfour: "/validation/",

        solution: "SOLUCIONES",
        solutionOne: "Atención al cliente",
        linkfive: "/atencion-al-cliente/",
        solutionTwo: "Telemarketing",
        linksix: "/marketing-ventas/",
        solutionThree: "Contact Center",
        linkseven: "/contact-center/",
        solutionFour: "Cobranzas",
        linkeight: "/software-cobranzas/",
        solutionFive: "WhatsApp",
        linkwhatsapp: "/whatsapp-business-api/",
        linkchatbots: "/chatbots-online/",
        solutionSix: "Chatbots",
        linkinstagram: "/chatbot-instagram/",
        solutionSeven: "Instagram",
        linkwebchat: "/chat-en-vivo-web/",
        solutionEight: "WebChat",

        company: "EMPRESA",
        companyOne: "Nosotros",
        linknine: "/nosotros/",
        companyTwo: "Casos de éxito",
        linkten: "/casos-de-exito/",
        companyThree: "Blog",
        linkeleven: "https://beexcc.com/blog/",
        companyFour: "Hablemos",
        linktwelve: "/hablemos/",
        companySix: "Documentación",
        linkdocs: "https://beexcc.com/docs/",
        companyFive: "Recursos",
        linktthirteen: "/recursos/",

        blog: "BLOG",
        blogone: "¿Cómo elegir el mejor software para call center?",
        linkblogone: "https://beexcc.com/blog/mejor-software-call-center/",

        blogtwo: "Plataforma Omnicanal: Qué es y cómo funciona",
        linkblogtwo: "https://beexcc.com/blog/plataforma-omnicanal/",

        blogthree: "¿Qué es WhatsApp Business API? - Guía completa 2023",
        linkblogthree: "https://beexcc.com/blog/que-es-whatsapp-business-api/",

        blogfour:
            "¿Cómo elegir el mejor chatbot? 5 características que debe cumplir",
        linkblogfour: "https://beexcc.com/blog/mejor-chatbot/",

        blogfive: "9 características que las herramientas de telemarketing",
        linkblogfive: "https://beexcc.com/blog/herramientas-telemarketing/",

        masblog: "Conoce más sobre nuestro blog",
        linkmasblog: "https://beexcc.com/blog/",

        address: "Jirón Pachacutec 1315, Of. 503, Jesús María, Lima, Perú",
        phone: "(+511) 277-4188",
        mail: "hi@beexcc.com",
        copyright: "Copyright© 2024 Beex Latam. Todos los derechos reservados.",
        privacyPolicy: "Política de Privacidad",
        privacyPolicyHref: "/politicas-privacidad",
        cookiesPolicy: "Política de Cookies",
        cookiesPolicyHref: "/politicas-cookies",
    },
    bannerVideo: {
        title: "¿Cómo automatizar y brindar experiencias únicas en el sector salud?",
        description: "<p>Descubre cómo simplificar y mejorar la atención médica con automatización inteligente. Explora nuevas formas de proporcionar experiencias únicas en el sector salud. Optimiza procesos y maximiza la eficiencia para un cuidado más efectivo y personalizado. <br /><br /> Conoce cómo la tecnología transforma la manera en que interactuamos y cuidamos de la salud de todos.</p>",
        image: imageVideo
    },
}

export default data
